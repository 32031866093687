<template>
  <vue-pdf-app pdf="http://www.pdf995.com/samples/pdf.pdf"></vue-pdf-app>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp,
  },
};
</script>
